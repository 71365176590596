import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  position: absolute;
  left: 20px;
`;

const Row = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffdc0e;
  text-decoration: none;

  :hover {
    color: #d3b81b;
  }

  p {
    padding: 0px;
    padding-left: 5px;
    margin: 0px;
  }
`;

const HomeButton = () => {
  return (
    <Container>
      <Row to="/">
        <FontAwesomeIcon icon={faChevronLeft} />
        <p>Back</p>
      </Row>
    </Container>
  );
}

export default HomeButton;