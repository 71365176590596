import React from 'react';
import styled from 'styled-components';
import Banner from '../components/banner';
import Card from '../components/card';
import { faCalculator, faCalendarDay, faStopwatch } from '@fortawesome/free-solid-svg-icons'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  const cards = [
    {
      title: "Converter",
      description: "How many Hollaback Girls old are you?",
      icon: faCalculator,
      link: "/convert",
    },
    {
      title: "Countdown",
      description: "How many Hollaback Girls until Christmas?",
      icon: faCalendarDay,
      link: "/countdown",
    },
    {
      title: "Timer",
      description: "Set a timer for 5 Hollaback Girls",
      icon: faStopwatch,
      link: "/timer",
    },
  ]
  return (
    <>
      <Layout>
        <Banner />
        <div>
          {cards.map(card =>
            <Card
              title={card.title}
              description={card.description}
              icon={card.icon}
              link={card.link}
              key={card.title}
            />)}
        </div>
      </Layout>
    </>
  );
}

export default Home;