import React from 'react';
import styled from 'styled-components';
import { default as Picker } from 'react-time-picker';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Field = styled(Picker)`
  select {
    color: #ffdc0e;
    display: inline-block;
    font-size: 16px;
    width: unset;

    :focus {
      outline: none;
    }
  }

  .react-time-picker__wrapper {
    border: none;
    border-bottom: 1px solid #ffdc0e;

    input {
      border-color: transparent;
      margin: 0px;
      color: #ffdc0e;

      ::placeholder {
        color: #ffdc0e;
        opacity: 0.5;
      }
    }

    span {
      color: #ffdc0e;
    }

    button {
      color: #ffdc0e;
      :hover {
        color: #2dd304;
      }
    }

    .react-time-picker__inputGroup__leadingZero {
      font-size: 16px;
    }
  }
`;

const TimePicker = ({ onChange, value }) => {
  return (
    <Field
      onChange={onChange}
      value={value}
      hourPlaceholder="hh"
      minutePlaceholder="mm"
      disableClock={true}
      showLeadingZeros={false}
      clearIcon={<FontAwesomeIcon icon={faTimes} size="lg" />}
    />
  );
}

export default TimePicker;