import React from 'react';
import Banner from '../components/banner';
import HomeButton from '../components/homeButton';
import SetTimer from '../components/setTimer';
import LiveTimer from '../components/liveTimer';
import { Routes, Route } from "react-router-dom";

const Timer = () => {
  return (
    <>
      <HomeButton />
      <Banner />
      <Routes>
        <Route path="/:hours-:minutes-:seconds" element={<LiveTimer />} />
        <Route path="/" element={<SetTimer />} />
      </Routes>
    </>
  );
}

export default Timer;