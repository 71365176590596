import React from 'react';
import styled from 'styled-components';
import { default as Picker } from 'react-date-picker';
import { faCalendarDay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Field = styled(Picker)`
  .react-date-picker__calendar {
    margin-top: 10px;
  }

  .react-calendar {
    border: 2px solid #ffdc0e;
  }

  .react-date-picker__wrapper {
    border: none;
    border-bottom: 1px solid #ffdc0e;

    input {
      border: none;
      margin: 0px;
      color: #ffdc0e;

      ::placeholder {
        color: #ffdc0e;
        opacity: 0.5;
      }
    }

    span {
      color: #ffdc0e;
    }

    button {
      color: #ffdc0e;
      :hover {
        color: #2dd304;
      }
    }

    .react-date-picker__inputGroup__leadingZero {
      font-size: 16px;
    }
  }
`;

const DatePicker = ({ onChange, value }) => {
  return (
    <Field
      onChange={onChange}
      value={value}
      monthPlaceholder="MM"
      dayPlaceholder="DD"
      yearPlaceholder="YYYY"
      showLeadingZeros={false}
      calendarIcon={<FontAwesomeIcon icon={faCalendarDay} size="lg" />}
      clearIcon={<FontAwesomeIcon icon={faTimes} size="lg" />}
    />
  );
}

export default DatePicker;