import React from 'react';
import styled from 'styled-components';

const ButtonStyle = styled.button`
    background-color: #ffdc0e;
    border: none;
    border-radius: 10px;
    padding: 5px 25px;

    p {
      background: radial-gradient(circle farthest-corner at 10% 20%, rgba(14,174,87) 0%, rgba(12,116,117) 90%) fixed;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0px;
      padding: 0px;
      font-size: 20px;
    }
    
    :hover, :active, :focus {
      p {
        background: radial-gradient(circle farthest-corner at 10% 20%, rgba(14,174,87) 0%, rgba(12,116,117) 90%) fixed;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      background-color: #d3b81b;
    }

    :focus {
      box-shadow: none;
    } 
`;

const Button = ({ text, onClick }) => {
  return (
    <ButtonStyle onClick={onClick}>
      <p>{text}</p>
    </ButtonStyle>
  );
}

export default Button;