import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  @media only screen and (max-width: 576px) {
    padding-bottom: 25px;
  }
`;

const Title = styled.h1`
  font-size: 60px;

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    font-size: 60px;
    width: 90%;
    margin: auto;
  }

  @media only screen and (max-width: 576px) {
    font-size: 40px;
  }
`;

const Banner = () => {
  return (
    <Container>
      <Title>How Many Hollaback Girls?</Title>
      <p>Have you ever wanted to measure time in terms of plays of <i>Hollaback Girl </i>
        by Gwen Stefani? Now you can!</p>
      <Title><span role="img" aria-label="bananas">🍌🍌🍌</span></Title>
    </Container>
  );
}

export default Banner;

