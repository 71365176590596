import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Button from '../components/button';
import { useParams } from 'react-router-dom';
import useSound from 'use-sound';
import Song from '../assets/hollaback-girl.mp3';

const circleWidth = 3;
const circleDivisions = 283;

const Container = styled.div`
  margin: auto;
  width: fit-content;
`;

const TimerContainer = styled.div`
  position: relative;
  height: 300px;
  width: 300px;
`;

const TimerSvg = styled.svg`
  transform: scaleX(-1);
`;

const TimerCircle = styled.circle.attrs(_ => ({
  cx: "50", cy: "50", r: "45"
}))`
  stroke-width: ${circleWidth}px;
  stroke: #00000030;
`;

const TimerPath = styled.path.attrs(props => ({
  strokeDasharray: `${props.timeLeft} ${circleDivisions}`,
  d: "M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0",
}))`
  stroke-width: ${circleWidth}px;
  stroke: #ffdc0e;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
`;

const TimerLabel = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LiveTimer = () => {
  let { hours, minutes, seconds } = useParams();
  const totalSeconds = (Number(hours) * 60 * 60) + (Number(minutes) * 60) + Number(seconds);

  const [timePassed, setTimePassed] = useState(0);
  const [timeLeft, setTimeLeft] = useState(totalSeconds);
  const [hollabacks, setHollabacks] = useState();
  const [timer, setTimer] = useState(null);

  const [play, extra] = useSound(Song);
  const stop = useRef(null);

  // set up the timer ticks
  useEffect(() => {
    const interval = setInterval(() => {
      setTimePassed(x => x + 1);
    }, 1000);
    setTimer(interval);

    // stop timer on component unmount
    return () => {
      stop.current();
      clearInterval(interval);
      setTimer(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update time left label based on time passed
  useEffect(() => {
    if (totalSeconds === timePassed) {
      play();
      clearInterval(timer);
      setTimer(null);
    }
    setTimeLeft(totalSeconds - timePassed);
  }, [totalSeconds, timePassed, timer]);

  // update hollabacks remaining based on time left
  useEffect(() => {
    setHollabacks(Math.round(timeLeft / 200 * 100) / 100);
  }, [timeLeft]);

  // grab the sound effect's stop fn
  useEffect(() => {
    stop.current = extra.stop;
  }, [extra]);

  const getTimerRingDashArray = () => {
    const rawFraction = timeLeft / totalSeconds;
    const fraction = rawFraction - (1 / totalSeconds) * (1 - rawFraction);
    return (Math.max(0, fraction * circleDivisions)).toFixed(0);
  };

  return (
    <Container>
      <TimerContainer>
        <TimerSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <g style={{ fill: 'none', stroke: 'none' }}>
            <TimerCircle />
            <TimerPath timeLeft={getTimerRingDashArray()} />
          </g>
        </TimerSvg>
        <TimerLabel>{hollabacks}</TimerLabel>
      </TimerContainer>
      <Button text="Stop" onClick={() => extra.stop()} />
    </Container>
  );
}

export default LiveTimer;