import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Banner from '../components/banner';
import HomeButton from '../components/homeButton';
import DatePicker from '../components/datePicker';
import TimePicker from '../components/timePicker';

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Container = styled.div`
  margin-bottom: 20px;
`;

const Result = styled.h1`
  font-size: 70px;
  margin-bottom: 20px;

  @media only screen and (max-width: 576px) {
    font-size: 40px;
  }
`;

const Countdown = () => {
  const [date, setDate] = useState();
  const [time, setTime] = useState('0:00');
  const [hollabacks, setHollabacks] = useState(0);

  useEffect(calculate, [date, time]);

  /**
   * Calculate number of hollaback girls until the given date and time.
   * If date isn't set, display nothing.
   * If time isn't set, assume it's midnight.
   */
  function calculate() {
    if (!date) {
      setHollabacks("---");
      return;
    }

    var [hours, minutes] = time ? time.split(":") : [0, 0];
    date.setHours(hours, minutes, 0, 0);
    const secondsToDate = (date.getTime() - Date.now()) / 1000;
    const conversion = secondsToDate / 200;
    const rounded = Math.round(conversion * 100) / 100;
    setHollabacks(rounded);
  }

  return (
    <>
      <HomeButton />
      <Banner />
      <Container>
        <Result>{hollabacks}</Result>
        <h1>Hollaback Girl{hollabacks === 1 ? '' : 's'}<br />until</h1>
      </Container>
      <InputRow>
        <DatePicker onChange={setDate} value={date} />
        <TimePicker value={time} onChange={setTime} />
      </InputRow>
    </>
  )
}

export default Countdown;