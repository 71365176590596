import React from 'react';
import styled from 'styled-components';
import { TextInput } from 'react-materialize';

const Container = styled.div`
  margin: 2px;
  padding: 0px 5px;
  width: ${props => props.width ?? '100%'};
  height: 60px;
  display: inline-block;

  .input-field>label {
    color: #ffdc0e !important;
    opacity: 0.5;
  }

  .input-field.col label {
    left: 0;
    font-size: 20px;
  }
`;

const Field = styled(TextInput)`
  border-bottom: 1px solid #ffdc0e !important;
  color: #ffdc0e;
 
  :focus {
    border-bottom: 1px solid #2dd304 !important;
  }
`;

const Input = ({ placeholder, onChange, value, width }) => {
  return (
    <Container width={width}>
      <Field
        label={placeholder}
        aria-label={placeholder}
        onChange={onChange}
        value={value}
        type="number"
        min="0"
      />
    </Container>
    // <Container
    //   placeholder={placeholder}
    //   aria-label={placeholder}
    //   type="number"
    //   min="0"
    //   value={value}
    //   onChange={onChange}
    // />
  );
}

export default Input;