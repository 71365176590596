import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const itemHeight = 30;

const Container = styled.div`
  width: fit-content;
  height: ${itemHeight * 9}px;
  border-radius: 10px;
  background: #20202017;
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  padding: 0px 10px;

  -webkit-mask-image: linear-gradient(to bottom,transparent 0%,black 30%,black 70%,transparent 100%);
  mask-image: linear-gradient(to bottom,transparent 0%,black 30%,black 70%,transparent 100%);
`;

const Scrollable = styled.div`
  padding: ${itemHeight * 4}px 0px;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: ${props => props.ready ? 'smooth' : 'unset'};
  overscroll-behavior: contain;
  ::-webkit-scrollbar {
    width: 0;  
    background: transparent; 
  }
`;

const Centered = styled.div`
  position: absolute;
  top: ${itemHeight * 4 - 2}px;
  height: ${itemHeight + 4}px;
`;

const Label = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  background: radial-gradient(circle farthest-corner at 10% 20%, rgba(14,174,87) 0%, rgba(12,116,117) 90%) fixed;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Item = styled.div.attrs(props => ({
  style: {
    transform: `rotateX(${props.offset * 15}deg)`
  }
}))`
  width: 100%;
  height: ${itemHeight}px;
  display: flex;
  justify-content: flex-end;
  opacity: 0.8;
  will-change: transform;
  /* transform: rotateX(${props => props.offset * 15}deg); */
`;

const Scroller = ({ items, defaultVal, label, onChange }) => {
  const [ready, setReady] = useState(false);
  const [selected, setSelected] = useState(items.indexOf(defaultVal));
  // const [curScroll, setCurScroll] = useState(items.indexOf(defaultVal) * itemHeight);
  const ref = useRef();

  useEffect(() => {
    ref.current.scrollTop = itemHeight * selected;
    onChange(items[selected]);
  }, [selected, onChange, items]);

  const handleEndScroll = useMemo(() =>
    _.debounce(() => {
      const y = ref.current.scrollTop;
      let scrollTo;

      if (y % itemHeight >= itemHeight / 2) scrollTo = ref.current.scrollTop + (itemHeight - (y % itemHeight));
      else scrollTo = ref.current.scrollTop - (y % itemHeight);
      ref.current.scrollTop = scrollTo;

      setSelected(scrollTo / itemHeight);
      if (!ready) setReady(true);
    }, 150), [ready]
  );

  const handleScroll = () => {
    // setCurScroll(ref.current.scrollTop);
    handleEndScroll();
  }

  return (
    <Container>
      <Scrollable onScroll={handleScroll} ref={ref} ready={ready}>
        {items.map((item, idx) => {
          // const offset = (curScroll / itemHeight) - idx;
          return <Item
            key={idx}
            onClick={() => setSelected(idx)}
          // offset={offset}
          >{item}</Item>
        })}
      </Scrollable>
      <Label>{label}</Label>
    </Container>
  );
}

const MultiScrollContainer = styled.div`
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  .scroller:first-child {
    .scroller-selected {
      background: red;
    }
  }
`;

const Selected = styled(Centered)`
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background: #0000007a;
  width: 100%;
  z-index: -1;
`;

const MultiScroller = ({ children }) => {
  return (
    <MultiScrollContainer>
      <Selected />
      {children}
    </MultiScrollContainer>
  );
}

export { Scroller, MultiScroller };