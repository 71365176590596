import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MultiScroller, Scroller } from '../components/scroller';
import Button from '../components/button';

const Container = styled.div`
  margin: auto;
  width: fit-content;
`;

const SetTimer = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  return (
    <Container>
      <MultiScroller>
        <Scroller
          items={[...Array(12).keys()]}
          defaultVal={0}
          label="hours"
          onChange={setHours}
        />
        <Scroller
          items={[...Array(60).keys()]}
          defaultVal={20}
          label="min"
          onChange={setMinutes}
        />
        <Scroller
          items={[...Array(60).keys()]}
          defaultVal={0}
          label="sec"
          onChange={setSeconds}
        />
      </MultiScroller>
      <Link to={`/timer/${hours}-${minutes}-${seconds}`}>
        <Button text="Start" />
      </Link>
    </Container>
  );
}

export default SetTimer;