import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Banner from '../components/banner';
import Input from '../components/input';
import HomeButton from '../components/homeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
  }
`;

const Form = styled.div`
  margin-bottom: 10px;

  @media only screen and (min-width: 1000px) {
    display: inline-block;
  }
`;

const FormRow = styled.div`
  margin-bottom: 10px;
`;

const ConvertIcon = styled(FontAwesomeIcon)`
  font-size: 50px;
  margin: 20px;

  @media only screen and (max-width: 1000px) {
    transform: rotate(90deg);
  }
`;

const secInMin = 60;
const secInHour = 60 * secInMin;
const secInDay = 24 * secInHour;
const secInWeek = 7 * secInDay;
const secInYear = 365 * secInDay;

const Converter = () => {
  const [years, setYears] = useState(0);
  const [weeks, setWeeks] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  // use this value for calculations
  const [hollabacks, setHollabacks] = useState(0);
  // display this value as a result of the calculations
  const [hollabacksDisplay, setHollabacksDisplay] = useState(null);

  // together the above 2 variables prevent a weird feedback loop

  function calcTimeToHollabacks() {
    const yearsToSec = secInYear * years;
    const weeksToSec = secInWeek * weeks;
    const daysToSec = secInDay * days;
    const hoursToSec = secInHour * hours;
    const minsToSec = secInMin * minutes;
    const totalSeconds = yearsToSec + weeksToSec + daysToSec + hoursToSec + minsToSec + seconds;
    const conversion = totalSeconds / 200;
    const rounded = Math.round(conversion * 100) / 100;
    setHollabacksDisplay(rounded);
  }

  function calcHollabacksToTime() {
    let hollaToSec = 200 * hollabacks;
    const ops = [[secInYear, setYears], [secInWeek, setWeeks], [secInDay, setDays],
    [secInHour, setHours], [secInMin, setMinutes], [1, setSeconds]];
    ops.forEach(([conversion, fn]) => {
      fn(Math.floor(hollaToSec / conversion));
      hollaToSec %= conversion;
    });
  }

  useEffect(calcTimeToHollabacks, [
    years, weeks, days, hours, minutes, seconds
  ]);

  useEffect(calcHollabacksToTime, [
    hollabacks
  ]);

  const getInputItem = (label, fn, value) => (
    <Input
      placeholder={label}
      onChange={e => {
        const val = e.target.value;
        if (validate(val)) {
          fn(Number(e.target.value))
        }
      }}
      value={getDisplayVal(value)}
      width="100px"
    />
  );

  const getDisplayVal = val => {
    return val === null ? "" : val.toString();
  }

  const validate = val => val >= 0;

  return (
    <>
      <HomeButton />
      <Banner />
      <Container>
        <Form>
          <FormRow>
            {getInputItem("Years", setYears, years)}
            {getInputItem("Weeks", setWeeks, weeks)}
            {getInputItem("Days", setDays, days)}
          </FormRow>
          <div>
            {getInputItem("Hours", setHours, hours)}
            {getInputItem("Minutes", setMinutes, minutes)}
            {getInputItem("Seconds", setSeconds, seconds)}
          </div>
        </Form>
        <ConvertIcon icon={faExchangeAlt} size="lg" />
        <Form>
          <Input
            placeholder="Hollaback Girls"
            value={getDisplayVal(hollabacksDisplay)}
            onChange={e => {
              const val = e.target.value;
              if (validate(val)) {
                setHollabacks(Number(e.target.value))
              }
            }}
            width="300px"
          />
        </Form>
      </Container>
      {/* 
      <div className='bottom-controls'>
        <div id='mode-btn-container' />
        <div />
        <audio controls autoPlay loop>
          <source src="./assets/hollaback-girl.mp3" />
        </audio>
      </div> */}
    </>
  );
}

export default Converter;