import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Container = styled.div`
  height: 245px;
  width: 240px;
  border-radius: 10px;
  border: 1px solid #ffdc0a;
  box-shadow: 1px 7px 20px 0px #043c3c;
  color: #ffdc0a;
  display: inline-block;
  margin: 30px;
  padding: 10px;
  transition: transform 0.2s;

  :hover {
    transform: scale(1.05, 1.05);
  }

  @media only screen and (max-width: 576px) {
    margin: 15px;
  }

  h3 {
    padding-bottom: 10px;
  }

  p {
    width: 85%;
    margin: auto;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 100px;
  margin-bottom: 5px;
`;

const Card = ({ title, description, link, icon }) => {
  return (
    <Link to={link}>
      <Container>
        <h3>{title}</h3>
        <Icon icon={icon} size="lg" />
        <p>{description}</p>
      </Container>
    </Link>
  );
}

export default Card;