import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/home';
import Converter from './pages/converter';
import Countdown from './pages/countdown';
import Timer from './pages/timer';
// import { Confetti } from './components/confetti';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/convert" element={<Converter />} />
          <Route path="/countdown" element={<Countdown />} />
          <Route path="/timer/*" element={<Timer />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>

      <script>

      </script>
    </div >
  );
}

export default App;
